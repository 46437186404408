import React from "react";
// import { Link } from "react-router-dom";

import '../styles/Events.css'


const Events = () => {


    return (
        <div>
            <div id="events-header">
                <i><h1>Events</h1></i>
                <i><h2>We do not currently have any events scheduled.</h2></i>
            </div>
            <div id="events-page-content-container">


                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                
            </div>
            

            <br></br>
            <br></br>
            <br></br>


        </div>
    )
}

export default Events;